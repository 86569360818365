import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
// import Cookies from 'js-cookie'

// Modules
import app from './app'
import appConfig from './app-config'
import auth from './auth'
import breadcrumb from './breadcrumb'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    auth,
    breadcrumb,
    verticalMenu,
  },
  plugins: [
    createPersistedState({
      paths: ['auth'],
      // storage: {
      //   getItem: key => Cookies.get(key),
      //   // Please see https://github.com/js-cookie/js-cookie#json, on how to handle JSON.
      //   // eslint-disable-next-line radix
      //   setItem: (key, value) => Cookies.set(key, value, { expires: parseInt(process.env.VUE_APP_MAX_TOKEN_CACHE_TIME), secure: false }),
      //   // setItem: (key, value) => Cookies.set(key, value, { secure: true }),
      //   removeItem: key => Cookies.remove(key),
      // },
    }),
  ],
  strict: process.env.DEV,
})
