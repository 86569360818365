export default [
  {
    path: '/appointments/calendar',
    name: 'appointment-calendar-index',
    component: () => import('@/views/appointment/calendar/Calendar.vue'),
    meta: {
      title: 'Calendar',
      pageTitle: 'Appointments',
      breadcrumb: [
        {
          text: 'Calendar',
          active: true,
        },
      ],
      resource: 'Calendar',
      action: 'read',
    },
  },
  {
    path: '/appointments/activity-types',
    name: 'appointment-activity-types-index',
    component: () => import('@/views/appointment/activity-types/ActivityTypeList.vue'),
    meta: {
      title: 'Activity Types',
      pageTitle: 'Appointments',
      breadcrumb: [
        {
          text: 'Activity Types',
          active: true,
        },
      ],
      resource: 'ActivityType',
      action: 'read',
    },
  },
  {
    path: '/appointments/activity-types/create',
    name: 'appointment-activity-types-create',
    component: () => import('@/views/appointment/activity-types/ActivityTypeCreate.vue'),
    meta: {
      title: 'Create New Activity Type',
      pageTitle: 'Appointments',
      navActiveLink: 'appointment-activity-types-index',
      breadcrumb: [
        {
          text: 'Activity Types',
          to: '/appointments/activity-types',
        },
        {
          text: 'Create New Activity Type',
          active: true,
        },
      ],
      resource: 'ActivityType',
      action: 'create',
    },
  },
  {
    path: '/appointments/activity-types/:id/edit',
    name: 'appointment-activity-types-edit',
    component: () => import('@/views/appointment/activity-types/ActivityTypeEdit.vue'),
    meta: {
      title: 'Edit Activity Type',
      pageTitle: 'Appointments',
      navActiveLink: 'appointment-activity-types-index',
      breadcrumb: [
        {
          text: 'Activity Types',
          to: '/appointments/activity-types',
        },
        {
          text: 'Edit Activity Type',
          active: true,
        },
      ],
      resource: 'ActivityType',
      action: 'update',
    },
  },
  {
    path: '/appointments/activity-types/:id/show',
    name: 'appointment-activity-types-show',
    component: () => import('@/views/appointment/activity-types/ActivityTypeDetail.vue'),
    meta: {
      title: 'Activity Type Detail',
      pageTitle: 'Appointments',
      navActiveLink: 'appointment-activity-types-index',
      breadcrumb: [
        {
          text: 'Activity Types',
          to: '/appointments/activity-types',
        },
        {
          text: 'Activity Type Detail',
          active: true,
        },
      ],
      resource: 'ActivityType',
      action: 'show',
    },
  },
  {
    path: '/appointments/activity-types/:parent/availability-scheme/create',
    name: 'appointment-availability-scheme-create',
    component: () => import('@/views/appointment/availability-schemes/AvailabilitySchemeCreate.vue'),
    meta: {
      title: 'Create Availability Scheme',
      pageTitle: 'Appointments',
      navActiveLink: 'appointment-activity-types-index',
      breadcrumb: [
        {
          text: 'Activity Types',
          to: '/appointments/activity-types',
        },
        {
          text: 'Activity Type Detail',
          to: '/appointments/activity-types/:parent/show',
        },
        {
          text: 'Create Availability Scheme',
          active: true,
        },
      ],
      resource: 'AvailabilityScheme',
      action: 'create',
    },
  },
  {
    path: '/appointments/activity-types/:parent/availability-schemes/:id/edit',
    name: 'appointment-availability-schemes-edit',
    component: () => import('@/views/appointment/availability-schemes/AvailabilitySchemeEdit.vue'),
    meta: {
      title: 'Edit Availability Scheme',
      pageTitle: 'Appointments',
      navActiveLink: 'appointment-activity-types-index',
      breadcrumb: [
        {
          text: 'Activity Types',
          to: '/appointments/activity-types',
        },
        {
          text: 'Activity Type Detail',
          to: '/appointments/activity-types/:parent/show',
        },
        {
          text: 'Edit Availability Scheme',
          active: true,
        },
      ],
      resource: 'AvailabilityScheme',
      action: 'update',
    },
  },
  {
    path: '/appointments/block-out-dates',
    name: 'appointment-block-out-dates-index',
    component: () => import('@/views/appointment/block-out-dates/BlockOutDateList.vue'),
    meta: {
      title: 'Block Out Dates',
      pageTitle: 'Appointments',
      breadcrumb: [
        {
          text: 'Settings',
          active: true,
        },
        {
          text: 'Block Out Dates',
          active: true,
        },
      ],
      resource: 'BlockOutDate',
      action: 'read',
    },
  },
  {
    path: '/appointments/block-out-dates/create',
    name: 'appointment-block-out-dates-create',
    component: () => import('@/views/appointment/block-out-dates/BlockOutDateCreate.vue'),
    meta: {
      title: 'Create New Block Out Date',
      pageTitle: 'Appointments',
      navActiveLink: 'appointment-block-out-dates-index',
      breadcrumb: [
        {
          text: 'Settings',
          active: true,
        },
        {
          text: 'Block Out Dates',
          to: '/appointments/block-out-dates',
        },
        {
          text: 'Create New Block Out Date',
          active: true,
        },
      ],
      resource: 'BlockOutDate',
      action: 'create',
    },
  },
  {
    path: '/appointments/block-out-dates/:id/edit',
    name: 'appointment-block-out-dates-edit',
    component: () => import('@/views/appointment/block-out-dates/BlockOutDateEdit.vue'),
    meta: {
      title: 'Edit Block Out Date',
      pageTitle: 'Appointments',
      navActiveLink: 'appointment-block-out-dates-index',
      breadcrumb: [
        {
          text: 'Settings',
          active: true,
        },
        {
          text: 'Block Out Dates',
          to: '/appointments/block-out-dates',
        },
        {
          text: 'Edit Block Out Date',
          active: true,
        },
      ],
      resource: 'BlockOutDate',
      action: 'update',
    },
  },
  {
    path: '/appointments/call-log-types',
    name: 'appointment-call-log-types-index',
    component: () => import('@/views/appointment/call-log-type/CallLogTypeList.vue'),
    meta: {
      title: 'Call Log Types',
      pageTitle: 'Appointments',
      breadcrumb: [
        {
          text: 'Settings',
          active: true,
        },
        {
          text: 'Call Log Types',
          active: true,
        },
      ],
      resource: 'CallLogType',
      action: 'read',
    },
  },
  {
    path: '/appointments/call-log-types/create',
    name: 'appointment-call-log-types-create',
    component: () => import('@/views/appointment/call-log-type/CallLogTypeCreate.vue'),
    meta: {
      title: 'Create New Call Log Type',
      pageTitle: 'Appointments',
      navActiveLink: 'appointment-call-log-types-index',
      breadcrumb: [
        {
          text: 'Settings',
          active: true,
        },
        {
          text: 'Call Log Types',
          to: '/appointments/call-log-types',
        },
        {
          text: 'Create New Call Log Type',
          active: true,
        },
      ],
      resource: 'CallLogType',
      action: 'create',
    },
  },
  {
    path: '/appointments/call-log-types/:id/edit',
    name: 'appointment-call-log-types-edit',
    component: () => import('@/views/appointment/call-log-type/CallLogTypeEdit.vue'),
    meta: {
      title: 'Edit Call Log Type',
      pageTitle: 'Appointments',
      navActiveLink: 'appointment-call-log-types-index',
      breadcrumb: [
        {
          text: 'Settings',
          active: true,
        },
        {
          text: 'Call Log Types',
          to: '/appointments/call-log-types',
        },
        {
          text: 'Edit Call Log Type',
          active: true,
        },
      ],
      resource: 'CallLogType',
      action: 'update',
    },
  },
  {
    path: '/appointments/call-logs',
    name: 'appointment-call-logs-index',
    component: () => import('@/views/appointment/call-log/CallLogList.vue'),
    meta: {
      title: 'All Call Logs',
      pageTitle: 'Appointments',
      breadcrumb: [
        {
          text: 'All Call Logs',
          active: true,
        },
      ],
      resource: 'CallLog',
      action: 'read',
    },
  },
  {
    path: '/appointments/call-logs/create',
    name: 'appointment-call-logs-create',
    component: () => import('@/views/appointment/call-log/CallLogCreate.vue'),
    meta: {
      title: 'Create New Call Log',
      pageTitle: 'Appointments',
      // navActiveLink: 'appointment-call-logs-index',
      breadcrumb: [
        {
          text: 'Call Logs',
          to: '/appointments/call-logs',
        },
        {
          text: 'Create New Call Log',
          active: true,
        },
      ],
      resource: 'CallLog',
      action: 'create',
    },
  },
  {
    path: '/appointments/call-logs/:id/edit',
    name: 'appointment-call-logs-edit',
    component: () => import('@/views/appointment/call-log/CallLogEdit.vue'),
    meta: {
      title: 'Edit Call Log',
      pageTitle: 'Appointments',
      breadcrumb: [
        {
          text: 'Call Logs',
          to: '/appointments/call-logs',
        },
        {
          text: 'Edit Call Log',
          active: true,
        },
      ],
      resource: 'CallLog',
      action: 'update',
    },
  },
  {
    path: '/appointments/call-logs/:id/show',
    name: 'appointment-call-logs-show',
    component: () => import('@/views/appointment/call-log/CallLogDetail.vue'),
    meta: {
      title: 'Call Log Detail',
      pageTitle: 'Appointments',
      navActiveLink: 'appointment-call-logs-index',
      breadcrumb: [
        {
          text: 'Call Logs',
          to: '/appointments/call-logs',
        },
        {
          text: 'Call Log Detail',
          active: true,
        },
      ],
      resource: 'CallLog',
      action: 'show',
    },
  },
  {
    path: '/appointments/events/:type',
    name: 'appointment-events-index',
    component: () => import('@/views/appointment/events/EventList.vue'),
    meta: {
      title: 'Events',
      pageTitle: 'Appointments',
      breadcrumb: [
        {
          text: 'Events',
          active: true,
        },
      ],
      resource: 'Event',
      action: 'read',
    },
  },
  {
    path: '/appointments/events/new/create',
    name: 'appointment-events-create',
    component: () => import('@/views/appointment/events/EventCreate.vue'),
    meta: {
      title: 'Create New Event',
      pageTitle: 'Appointments',
      // navActiveLink: 'appointment-events-index',
      breadcrumb: [
        {
          text: 'Events',
          to: '/appointments/events/all-event',
        },
        {
          text: 'Create New Event',
          active: true,
        },
      ],
      resource: 'Event',
      action: 'create',
    },
  },
  {
    path: '/appointments/events/:id/edit',
    name: 'appointment-events-edit',
    component: () => import('@/views/appointment/events/EventEdit.vue'),
    meta: {
      title: 'Edit Event',
      pageTitle: 'Appointments',
      // navActiveLink: 'appointment-events-index',
      breadcrumb: [
        {
          text: 'Events',
          to: '/appointments/events/all-event',
        },
        {
          text: 'Edit Event',
          active: true,
        },
      ],
      resource: 'Event',
      action: 'update',
    },
  },
  {
    path: '/appointments/events/:id/show',
    name: 'appointment-events-show',
    component: () => import('@/views/appointment/events/EventDetail.vue'),
    meta: {
      title: 'Event Detail',
      pageTitle: 'Appointments',
      // navActiveLink: 'appointment-events-index',
      breadcrumb: [
        {
          text: 'Events',
          to: '/appointments/events/all-event',
        },
        {
          text: 'Event Detail',
          active: true,
        },
      ],
      resource: 'Event',
      action: 'show',
    },
  },
  {
    path: '/appointments/events/:parent/packages/create',
    name: 'appointment-events-package-create',
    component: () => import('@/views/appointment/events/package/PackageCreate.vue'),
    meta: {
      title: 'Create Event Package',
      pageTitle: 'Appointments',
      // navActiveLink: 'appointment-events-index',
      breadcrumb: [
        {
          text: 'Events',
          to: '/appointments/events/all-event',
        },
        {
          text: 'Event Detail',
          to: '/appointments/events/:parent/show',
        },
        {
          text: 'Package Create',
          active: true,
        },
      ],
      resource: 'EventPackage',
      action: 'create',
    },
  },
  {
    path: '/appointments/events/:parent/packages/:id/edit',
    name: 'appointment-event-packages-edit',
    component: () => import('@/views/appointment/events/package/PackageEdit.vue'),
    meta: {
      title: 'Edit Event Package',
      pageTitle: 'Appointments',
      // navActiveLink: 'appointment-events-index',
      breadcrumb: [
        {
          text: 'Events',
          to: '/appointments/events/all-event',
        },
        {
          text: 'Event Detail',
          to: '/appointments/events/:parent/show',
        },
        {
          text: 'Package Edit',
          active: true,
        },
      ],
      resource: 'EventPackage',
      action: 'update',
    },
  },
  {
    path: '/appointments/events/:parent/packages/:id/show',
    name: 'appointment-event-packages-show',
    component: () => import('@/views/appointment/events/package/PackageDetail.vue'),
    meta: {
      title: 'Event Package Detail',
      pageTitle: 'Appointments',
      // navActiveLink: 'appointment-events-index',
      breadcrumb: [
        {
          text: 'Events',
          to: '/appointments/events/all-event',
        },
        {
          text: 'Event Detail',
          to: '/appointments/events/:parent/show',
        },
        {
          text: 'Package Detail',
          active: true,
        },
      ],
      resource: 'EventPackage',
      action: 'show',
    },
  },
  {
    path: '/appointments/events/:parent/time-slots/create',
    name: 'appointment-event-time-slots-create',
    component: () => import('@/views/appointment/events/time-slot/TimeSlotCreate.vue'),
    meta: {
      title: 'Create Event Time Slot',
      pageTitle: 'Appointments',
      // navActiveLink: 'appointment-events-index',
      breadcrumb: [
        {
          text: 'Events',
          to: '/appointments/events/all-event',
        },
        {
          text: 'Event Detail',
          to: '/appointments/events/:parent/show',
        },
        {
          text: 'Time Slot',
          active: true,
        },
      ],
      resource: 'EventTimeSlot',
      action: 'create',
    },
  },
  {
    path: '/appointments/bookings/:type',
    name: 'appointment-bookings-index',
    component: () => import('@/views/appointment/bookings/BookingList.vue'),
    meta: {
      title: 'Bookings',
      pageTitle: 'Appointments',
      breadcrumb: [
        {
          text: 'Bookings',
          active: true,
        },
      ],
      resource: 'Booking',
      action: 'read',
    },
  },
  {
    path: '/appointments/bookings/new/create',
    name: 'appointment-bookings-create',
    component: () => import('@/views/appointment/bookings/BookingCreate.vue'),
    meta: {
      title: 'Create Booking',
      pageTitle: 'Appointments',
      // navActiveLink: 'appointment-bookings-index',
      breadcrumb: [
        {
          text: 'Bookings',
          to: '/appointments/bookings/all-booking',
        },
        {
          text: 'Create Booking',
          active: true,
        },
      ],
      resource: 'Booking',
      action: 'create',
    },
  },
  {
    path: '/appointments/bookings/:id/show',
    name: 'appointment-bookings-show',
    component: () => import('@/views/appointment/bookings/BookingDetail.vue'),
    meta: {
      title: 'Booking Detail',
      pageTitle: 'Appointments',
      // navActiveLink: 'appointment-bookings-index',
      breadcrumb: [
        {
          text: 'Bookings',
          to: '/appointments/bookings/all-booking',
        },
        {
          text: 'Booking Detail',
          active: true,
        },
      ],
      resource: 'Booking',
      action: 'show',
    },
  },
  {
    path: '/appointments/bookings/:id/edit',
    name: 'appointment-bookings-edit',
    component: () => import('@/views/appointment/bookings/BookingEdit.vue'),
    meta: {
      title: 'Edit Booking',
      pageTitle: 'Appointments',
      // navActiveLink: 'appointment-bookings-index',
      breadcrumb: [
        {
          text: 'Bookings',
          to: '/appointments/bookings/all-booking',
        },
        {
          text: 'Edit Booking',
          active: true,
        },
      ],
      resource: 'Booking',
      action: 'update',
    },
  },
]
