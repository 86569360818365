export default [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      title: 'Login',
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/page-not-found',
    name: 'page-not-found',
    component: () => import('@/views/pages/error/NotFoundError.vue'),
    meta: {
      title: 'Page Not Found',
      layout: 'full',
      frontend: true,
    },
  },
  {
    path: '/operation/download/kitchen-view',
    name: 'operation-download-kitchen-view',
    component: () => import('@/views/operation/kitchen-view/NewPdf.vue'),
    meta: {
      title: 'Kitchen View Generate PDF',
      layout: 'full',
      frontend: true,
    },
  },
  {
    path: '/operation/download/roster/:id',
    name: 'operation-download-roster',
    component: () => import('@/views/operation/monthly-roster/NewPdf.vue'),
    meta: {
      title: 'Monthly Roster Download',
      layout: 'full',
      frontend: true,
    },
  },
  {
    path: '/operation/download/service-task',
    name: 'operation-download-service-task',
    component: () => import('@/views/operation/calendar/NewPdf.vue'),
    meta: {
      title: 'Service Request Task Calendar Download',
      layout: 'full',
      frontend: true,
    },
  },
  {
    path: '/operations/service-forms/:id/form/pdf-view',
    name: 'operation-service-forms-form-pdf-view',
    component: () => import('@/views/operation/service-form/FormViewPdf.vue'),
    meta: {
      title: 'Service Form',
      layout: 'full',
      frontend: true,
    },
  },
  {
    path: '/vendor/self-registration',
    name: 'vendor-self-registration',
    component: () => import('@/views/purchase/vendors/VendorSelfRegistration.vue'),
    meta: {
      title: 'Vendor Self Registration',
      layout: 'full',
      frontend: true,
    },
  },
  {
    path: '/vendor/self-registration/:id',
    name: 'existing-vendor-self-registration',
    component: () => import('@/views/purchase/vendors/ExistingVendorSelfRegistration.vue'),
    meta: {
      title: 'Vendor Self Registration',
      layout: 'full',
      frontend: true,
    },
  },
  {
    path: '/vendor/information/:id',
    name: 'vendor-information-frontend',
    component: () => import('@/views/purchase/vendors/VendorDetailFrontend.vue'),
    meta: {
      title: 'Vendor Information',
      layout: 'full',
      frontend: true,
    },
  },
  {
    path: '/purchase-request/download/prf/:id',
    name: 'purchase-request-download-prf',
    component: () => import('@/views/purchase/purchase-request/purchase-request/NewPdf.vue'),
    meta: {
      title: 'PRF Download',
      layout: 'full',
      frontend: true,
    },
  },
  {
    path: '/purchase-request/download/qcf/:id',
    name: 'purchase-request-download-qcf',
    component: () => import('@/views/purchase/quotations/NewPdf.vue'),
    meta: {
      title: 'QCF Download',
      layout: 'full',
      frontend: true,
    },
  },
  {
    path: '/purchase-request/download/po/:id',
    name: 'purchase-request-download-po',
    component: () => import('@/views/purchase/purchase-order/NewPdf.vue'),
    meta: {
      title: 'PO Download',
      layout: 'full',
      frontend: true,
    },
  },
  {
    path: '/operations/service-requests/form/:id/detail',
    name: 'operation-service-requests-form-detail-view',
    component: () => import('@/views/operation/service-request/form-templates-pdf/PdfView.vue'),
    meta: {
      title: 'Service Request Form PDF',
      layout: 'full',
      frontend: true,
    },
  },
  {
    path: '/purchase-request/download/ncf/:id',
    name: 'purchase-request-download-ncf',
    component: () => import('@/views/purchase/ncf/NewPdf.vue'),
    meta: {
      title: 'NCF Download',
      layout: 'full',
      frontend: true,
    },
  },
  {
    path: '/vendors/evaluation/preview',
    name: 'vendors-evaluation-preview',
    component: () => import('@/views/purchase/evaluation/PreviewPdf.vue'),
    meta: {
      title: 'Evaluation Preview',
      layout: 'full',
      frontend: true,
    },
  },
  {
    path: '/vendors/evaluation/verify/preview',
    name: 'vendors-evaluation-verify-preview',
    component: () => import('@/views/purchase/evaluation/EvaluationPreviewPdf.vue'),
    meta: {
      title: 'Evaluation Preview',
      layout: 'full',
      frontend: true,
    },
  },
  {
    path: '/app/file/soa/statement',
    name: 'app-file-payment-statement',
    component: () => import('@/views/apps/SoaPdf.vue'),
    meta: {
      title: 'Payment Statement Preview',
      layout: 'full',
      frontend: true,
    },
  },
  {
    path: '/app/receipt/download',
    name: 'app-receipt-download',
    component: () => import('@/views/apps/ReceiptPdf.vue'),
    meta: {
      title: 'Receipt Preview',
      layout: 'full',
      frontend: true,
    },
  },
  {
    path: '/app/invoice/download',
    name: 'app-invoice-download',
    component: () => import('@/views/apps/InvoicePdf.vue'),
    meta: {
      title: 'Invoice Preview',
      layout: 'full',
      frontend: true,
    },
  },
  {
    path: '/pages/authentication/login-v1',
    name: 'auth-login-v1',
    component: () => import('@/views/pages/authentication/Login-v1.vue'),
    meta: {
      title: '',
      layout: 'full',
    },
  },
  {
    path: '/pages/authentication/login-v2',
    name: 'auth-login-v2',
    component: () => import('@/views/pages/authentication/Login-v2.vue'),
    meta: {
      title: '',
      layout: 'full',
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/pages/authentication/Register.vue'),
    meta: {
      title: '',
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/pages/authentication/register-v1',
    name: 'auth-register-v1',
    component: () => import('@/views/pages/authentication/Register-v1.vue'),
    meta: {
      title: '',
      layout: 'full',
    },
  },
  {
    path: '/pages/authentication/register-v2',
    name: 'auth-register-v2',
    component: () => import('@/views/pages/authentication/Register-v2.vue'),
    meta: {
      title: '',
      layout: 'full',
    },
  },
  {
    path: '/pages/authentication/forgot-password-v1',
    name: 'auth-forgot-password',
    component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
    meta: {
      title: 'Forget Password',
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password-v1',
    component: () => import('@/views/pages/authentication/ForgotPassword-v1.vue'),
    meta: {
      title: 'Forget Password',
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/pages/authentication/forgot-password-v2',
    name: 'auth-forgot-password-v2',
    component: () => import('@/views/pages/authentication/ForgotPassword-v2.vue'),
    meta: {
      title: '',
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/reset-password/:token',
    name: 'auth-reset-password-v1',
    component: () => import('@/views/pages/authentication/ResetPassword-v1.vue'),
    meta: {
      title: 'Reset Password',
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/pages/authentication/reset-password-v2',
    name: 'auth-reset-password-v2',
    component: () => import('@/views/pages/authentication/ResetPassword-v2.vue'),
    meta: {
      title: '',
      layout: 'full',
    },
  },
  {
    path: '/pages/account-setting',
    name: 'pages-account-setting',
    component: () => import('@/views/pages/account-setting/AccountSetting.vue'),
    meta: {
      pageTitle: 'Account Settings',
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'Account Settings',
          active: true,
        },
      ],
    },
  },
  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: () => import('@/views/pages/authentication/NotAuthorized.vue'),
    meta: {
      title: '',
      layout: 'full',
    },
  },
  {
    path: '/test-sticky-scroll',
    name: 'test-sticky-scroll',
    component: () => import('@/views/front-end/ScrollTest.vue'),
    meta: {
      pageTitle: 'Operations',
      // navActiveLink: 'appointment-bookings-index',
      breadcrumb: [
        {
          text: 'Bookings',
          to: '/appointments/bookings/all-booking',
        },
        {
          text: 'Booking Detail',
          active: true,
        },
      ],
      frontend: true,
    },
  },
  {
    path: '/payment-feedback',
    name: 'payment-feedback',
    component: () => import('@/views/front-end/PaymentFeedback.vue'),
    meta: {
      title: '',
      layout: 'full',
      frontend: true,
    },
  },
  {
    path: '/appointments',
    name: 'appointments-front-end',
    component: () => import('@/views/front-end/AppointmentNew.vue'),
    meta: {
      title: '',
      layout: 'full',
      frontend: true,
    },
  },
  {
    path: '/service-form-booking',
    name: 'service-form-booking-frontend',
    component: () => import('@/views/front-end/ServiceBookingNew.vue'),
    meta: {
      title: '',
      layout: 'full',
      frontend: true,
    },
  },
  {
    path: '/upcoming-events',
    name: 'upcoming-events',
    component: () => import('@/views/front-end/Events.vue'),
    meta: {
      title: '',
      layout: 'full',
      frontend: true,
    },
  },
  {
    path: '/cycle-validation',
    name: 'cycle-validation',
    component: () => import('@/views/front-end/CycleValidation.vue'),
    meta: {
      title: '',
      layout: 'full',
      frontend: true,
    },
  },
  {
    path: '/activity/:id/booking/verification',
    name: 'activity-booking-frontend-verification',
    component: () => import('@/views/front-end/BookingCustomerNew.vue'),
    meta: {
      title: '',
      layout: 'full',
      frontend: true,
    },
  },
  {
    path: '/activity/:id/booking',
    name: 'activity-booking-frontend',
    component: () => import('@/views/front-end/ActivityBookingNew.vue'),
    meta: {
      title: '',
      layout: 'full',
      frontend: true,
    },
  },
  {
    path: '/:slug',
    name: 'event-detail-frontend',
    component: () => import('@/views/front-end/EventDetail.vue'),
    meta: {
      title: '',
      layout: 'full',
      frontend: true,
    },
  },
  {
    path: '/:slug/booking',
    name: 'event-booking-frontend',
    component: () => import('@/views/front-end/EventBooking.vue'),
    meta: {
      title: '',
      layout: 'full',
      frontend: true,
    },
  },
]
