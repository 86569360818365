export default [
  {
    path: '/operations/calendar',
    name: 'operation-calendar-index',
    component: () => import('@/views/operation/calendar/Calendar.vue'),
    meta: {
      title: 'Calendar',
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Calendar',
          active: true,
        },
      ],
      resource: 'OperationCalendar',
      action: 'read',
    },
  },
  {
    path: '/operations/roster-groups',
    name: 'operation-roster-groups-index',
    component: () => import('@/views/operation/roster-group/RosterGroupList.vue'),
    meta: {
      title: 'Roster Groups',
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Rosters',
          to: '',
        },
        {
          text: 'Roster Groups',
          active: true,
        },
      ],
      resource: 'RosterGroup',
      action: 'read',
    },
  },
  {
    path: '/operations/roster-groups/create',
    name: 'operation-roster-groups-create',
    component: () => import('@/views/operation/roster-group/RosterGroupCreate.vue'),
    meta: {
      title: 'Create New Roster Group',
      pageTitle: 'Operations',
      navActiveLink: 'operation-roster-groups-index',
      breadcrumb: [
        {
          text: 'Rosters',
          to: '',
        },
        {
          text: 'Roster Groups',
          to: '/operations/roster-groups',
        },
        {
          text: 'Create New Roster Group',
          active: true,
        },
      ],
      resource: 'RosterGroup',
      action: 'create',
    },
  },
  {
    path: '/operations/roster-groups/:id/edit',
    name: 'operation-roster-groups-edit',
    component: () => import('@/views/operation/roster-group/RosterGroupEdit.vue'),
    meta: {
      title: 'Edit Roster Group',
      pageTitle: 'Operations',
      navActiveLink: 'operation-roster-groups-index',
      breadcrumb: [
        {
          text: 'Rosters',
          to: '',
        },
        {
          text: 'Roster Groups',
          to: '/operations/roster-groups',
        },
        {
          text: 'Edit Roster Group',
          active: true,
        },
      ],
      resource: 'RosterGroup',
      action: 'update',
    },
  },
  {
    path: '/operations/roster-status-types',
    name: 'operation-roster-status-types-index',
    component: () => import('@/views/operation/roster-status-type/RosterStatusTypeList.vue'),
    meta: {
      title: 'Roster Status Types',
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Rosters',
          to: '',
        },
        {
          text: 'Status Types',
          active: true,
        },
      ],
      resource: 'RosterStatusType',
      action: 'read',
    },
  },
  {
    path: '/operations/roster-status-types/create',
    name: 'operation-roster-status-types-create',
    component: () => import('@/views/operation/roster-status-type/RosterStatusTypeCreate.vue'),
    meta: {
      title: 'Create New Roster Status Type',
      pageTitle: 'Operations',
      navActiveLink: 'operation-roster-status-types-index',
      breadcrumb: [
        {
          text: 'Rosters',
          to: '',
        },
        {
          text: 'Status Types',
          to: '/operations/roster-status-types',
        },
        {
          text: 'Create New Status Type',
          active: true,
        },
      ],
      resource: 'RosterStatusType',
      action: 'create',
    },
  },
  {
    path: '/operations/roster-status-types/:id/edit',
    name: 'operation-roster-status-types-edit',
    component: () => import('@/views/operation/roster-status-type/RosterStatusTypeEdit.vue'),
    meta: {
      title: 'Edit Roster Status Type',
      pageTitle: 'Operations',
      navActiveLink: 'operation-roster-status-types-index',
      breadcrumb: [
        {
          text: 'Rosters',
          to: '',
        },
        {
          text: 'Status Types',
          to: '/operations/roster-status-types',
        },
        {
          text: 'Edit Status Type',
          active: true,
        },
      ],
      resource: 'RosterStatusType',
      action: 'update',
    },
  },
  {
    path: '/operations/roster-annual-groupings',
    name: 'operation-roster-annual-groupings-index',
    component: () => import('@/views/operation/annual-grouping/AnnualGrouping.vue'),
    meta: {
      title: 'Annual Grouping',
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Rosters',
          to: '',
        },
        {
          text: 'Annual Grouping',
          active: true,
        },
      ],
      resource: 'RosterYear',
      action: 'read',
    },
  },
  {
    path: '/operations/roster-annual-groupings/create',
    name: 'operation-roster-annual-groupings-create',
    component: () => import('@/views/operation/annual-grouping/AnnualGroupingCreate.vue'),
    meta: {
      title: 'Create New Year',
      pageTitle: 'Operations',
      navActiveLink: 'operation-roster-annual-groupings-index',
      breadcrumb: [
        {
          text: 'Rosters',
          to: '',
        },
        {
          text: 'Annual Grouping',
          to: '/operations/roster-annual-groupings',
        },
        {
          text: 'Create New Year',
          active: true,
        },
      ],
      resource: 'RosterYear',
      action: 'create',
    },
  },
  {
    path: '/operations/roster-annual-groupings/:id/edit',
    name: 'operation-roster-annual-groupings-edit',
    component: () => import('@/views/operation/annual-grouping/AnnualGroupingEdit.vue'),
    meta: {
      title: 'Edit Year',
      pageTitle: 'Operations',
      navActiveLink: 'operation-roster-annual-groupings-index',
      breadcrumb: [
        {
          text: 'Rosters',
          to: '',
        },
        {
          text: 'Annual Grouping',
          to: '/operations/roster-annual-groupings',
        },
        {
          text: 'Edit Year',
          active: true,
        },
      ],
      resource: 'RosterYear',
      action: 'update',
    },
  },
  {
    path: '/operations/roster-monthly',
    name: 'operation-roster-monthly-index',
    component: () => import('@/views/operation/monthly-roster/RosterIndex.vue'),
    meta: {
      title: 'Monthly Roster',
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Rosters',
          to: '',
        },
        {
          text: 'Monthly Roster',
          active: true,
        },
      ],
      resource: 'RosterMonth',
      action: 'read',
    },
  },
  {
    path: '/operations/roster-monthly/create',
    name: 'operation-roster-monthly-create',
    component: () => import('@/views/operation/monthly-roster/RosterCreate.vue'),
    meta: {
      title: 'Create New Month',
      pageTitle: 'Operations',
      navActiveLink: 'operation-roster-monthly-index',
      breadcrumb: [
        {
          text: 'Rosters',
          to: '',
        },
        {
          text: 'Monthly Roster',
          to: '/operations/roster-monthly',
        },
        {
          text: 'Create New Month',
          active: true,
        },
      ],
      resource: 'RosterMonth',
      action: 'create',
    },
  },
  {
    path: '/operations/roster-monthly/:id/edit',
    name: 'operation-roster-monthly-edit',
    component: () => import('@/views/operation/monthly-roster/RosterEdit.vue'),
    meta: {
      title: 'Edit Month',
      pageTitle: 'Operations',
      navActiveLink: 'operation-roster-monthly-index',
      breadcrumb: [
        {
          text: 'Rosters',
          to: '',
        },
        {
          text: 'Monthly Roster',
          to: '/operations/roster-monthly',
        },
        {
          text: 'Edit Month',
          active: true,
        },
      ],
      resource: 'RosterMonth',
      action: 'update',
    },
  },
  {
    path: '/operations/task-block-components',
    name: 'operation-task-block-components-index',
    component: () => import('@/views/operation/task-block-component/TaskBlockList.vue'),
    meta: {
      title: 'Task Blocks',
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Settings',
          to: '',
        },
        {
          text: 'Task Blocks',
          active: true,
        },
      ],
      resource: 'TaskBlockComponent',
      action: 'read',
    },
  },
  {
    path: '/operations/task-block-components/create',
    name: 'operation-task-block-components-create',
    component: () => import('@/views/operation/task-block-component/TaskBlockCreate.vue'),
    meta: {
      title: 'Create New Task Block',
      pageTitle: 'Operations',
      navActiveLink: 'operation-task-block-components-index',
      breadcrumb: [
        {
          text: 'Settings',
          to: '',
        },
        {
          text: 'Task Blocks',
          to: '/operations/task-block-components',
        },
        {
          text: 'Create New Task Block',
          active: true,
        },
      ],
      resource: 'TaskBlockComponent',
      action: 'create',
    },
  },
  {
    path: '/operations/task-block-components/:id/edit',
    name: 'operation-task-block-components-edit',
    component: () => import('@/views/operation/task-block-component/TaskBlockEdit.vue'),
    meta: {
      title: 'Edit Task Block',
      pageTitle: 'Operations',
      navActiveLink: 'operation-task-block-components-index',
      breadcrumb: [
        {
          text: 'Settings',
          to: '',
        },
        {
          text: 'Task Blocks',
          to: '/operations/task-block-components',
        },
        {
          text: 'Edit Task Block',
          active: true,
        },
      ],
      resource: 'TaskBlockComponent',
      action: 'update',
    },
  },
  {
    path: '/operations/task-block-components/:id/show',
    name: 'operation-task-block-components-show',
    component: () => import('@/views/operation/task-block-component/TaskBlockDetail.vue'),
    meta: {
      title: 'Task Block Detail',
      pageTitle: 'Operations',
      navActiveLink: 'operation-task-block-components-index',
      breadcrumb: [
        {
          text: 'Settings',
          to: '',
        },
        {
          text: 'Task Blocks',
          to: '/operations/task-block-components',
        },
        {
          text: 'Task Block Detail',
          active: true,
        },
      ],
      resource: 'TaskBlockComponent',
      action: 'show',
    },
  },
  {
    path: '/operations/workflow-categories',
    name: 'operation-workflow-category-index',
    component: () => import('@/views/operation/work-flow-category/WorkFlowCategoryList.vue'),
    meta: {
      title: 'Workflow Category',
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Settings',
          to: '',
        },
        {
          text: 'Workflow Category',
          active: true,
        },
      ],
      resource: 'WorkFlowCategory',
      action: 'read',
    },
  },
  {
    path: '/operations/workflow-categories/create',
    name: 'operation-workflow-category-create',
    component: () => import('@/views/operation/work-flow-category/WorkFlowCategoryCreate.vue'),
    meta: {
      title: 'Create Workflow Category',
      pageTitle: 'Operations',
      navActiveLink: 'operation-workflow-category-index',
      breadcrumb: [
        {
          text: 'Settings',
          to: '',
        },
        {
          text: 'Workflow Category',
          to: '/operations/workflow-categories',
        },
        {
          text: 'Create Workflow Category',
          active: true,
        },
      ],
      resource: 'WorkFlowCategory',
      action: 'create',
    },
  },
  {
    path: '/operations/workflow-categories/:id/edit',
    name: 'operation-workflow-category-edit',
    component: () => import('@/views/operation/work-flow-category/WorkFlowCategoryEdit.vue'),
    meta: {
      title: 'Edit Workflow Category',
      pageTitle: 'Operations',
      navActiveLink: 'operation-workflow-category-index',
      breadcrumb: [
        {
          text: 'Settings',
          to: '',
        },
        {
          text: 'Workflow Category',
          to: '/operations/workflow-categories',
        },
        {
          text: 'Edit Workflow Category',
          active: true,
        },
      ],
      resource: 'WorkFlowCategory',
      action: 'update',
    },
  },
  {
    path: '/operations/workflows/:type',
    name: 'operation-workflows-index',
    component: () => import('@/views/operation/work-flow/WorkFlowList.vue'),
    meta: {
      title: 'Workflow List',
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Settings',
          to: '',
        },
        {
          text: 'Workflow List',
          active: true,
        },
      ],
      resource: 'WorkFlow',
      action: 'read',
    },
  },
  {
    path: '/operations/workflows/new/create',
    name: 'operation-workflows-create',
    component: () => import('@/views/operation/work-flow/WorkFlowCreate.vue'),
    meta: {
      title: 'Create New Workflow',
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Settings',
          to: '',
        },
        {
          text: 'Workflow List',
          to: '/operations/workflows/all-work-flows',
        },
        {
          text: 'Create New Workflow',
          active: true,
        },
      ],
      resource: 'WorkFlow',
      action: 'create',
    },
  },
  {
    path: '/operations/workflows/:id/edit',
    name: 'operation-workflows-edit',
    component: () => import('@/views/operation/work-flow/WorkFlowEdit.vue'),
    meta: {
      title: 'Edit Workflow',
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Settings',
          to: '',
        },
        {
          text: 'Workflow List',
          to: '/operations/workflows/all-work-flows',
        },
        {
          text: 'Edit Workflow',
          active: true,
        },
      ],
      resource: 'WorkFlow',
      action: 'update',
    },
  },
  {
    path: '/operations/workflows/:id/show',
    name: 'operation-workflows-show',
    component: () => import('@/views/operation/work-flow/WorkFlowDetail.vue'),
    meta: {
      title: 'Workflow Detail',
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Settings',
          to: '',
        },
        {
          text: 'Workflow List',
          to: '/operations/workflows/all-work-flows',
        },
        {
          text: 'Workflow Detail',
          active: true,
        },
      ],
      resource: 'WorkFlow',
      action: 'show',
    },
  },
  {
    path: '/operations/workflows/:parent/add-task',
    name: 'operation-workflows-add-task',
    component: () => import('@/views/operation/work-flow/AddTask.vue'),
    meta: {
      title: 'Add a Task',
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Settings',
          to: '',
        },
        {
          text: 'Workflow List',
          to: '/operations/workflows/all-work-flows',
        },
        {
          text: 'Workflow Detail',
          to: '/operations/workflows/:parent/show',
        },
        {
          text: 'Add a Task',
          active: true,
        },
      ],
      resource: 'WorkFlowTask',
      action: 'create',
    },
  },
  {
    path: '/operations/workflows/:parent/task/:id/edit',
    name: 'operation-workflows-task-edit',
    component: () => import('@/views/operation/work-flow/EditTask.vue'),
    meta: {
      title: 'Edit Task',
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Settings',
          to: '',
        },
        {
          text: 'Workflow List',
          to: '/operations/workflows/all-work-flows',
        },
        {
          text: 'Workflow Detail',
          to: '/operations/workflows/:parent/show',
        },
        {
          text: 'Edit Task',
          active: true,
        },
      ],
      resource: 'WorkFlowTask',
      action: 'update',
    },
  },
  {
    path: '/operations/service-descriptions',
    name: 'operation-service-descriptions',
    component: () => import('@/views/operation/service-form/ServiceDescription.vue'),
    meta: {
      title: 'Service Description',
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Settings',
          to: '',
        },
        {
          text: 'Service Description',
          active: true,
        },
      ],
      resource: 'ServiceDescription',
      action: 'create',
    },
  },
  {
    path: '/operations/service-forms/:type',
    name: 'operation-service-forms-index',
    component: () => import('@/views/operation/service-form/ServiceFormList.vue'),
    meta: {
      title: 'Service Forms',
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Service Forms',
          active: true,
        },
      ],
      resource: 'ServiceForm',
      action: 'read',
    },
  },
  {
    path: '/operations/service-forms/new/create',
    name: 'operation-service-forms-create',
    component: () => import('@/views/operation/service-form/ServiceFormCreate.vue'),
    meta: {
      title: 'Create New Service Form',
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Service Forms',
          to: '/operations/service-forms/all-service-forms',
        },
        {
          text: 'Create New Service Form',
          active: true,
        },
      ],
      resource: 'ServiceForm',
      action: 'create',
    },
  },
  {
    path: '/operations/service-forms/:id/edit',
    name: 'operation-service-forms-edit',
    component: () => import('@/views/operation/service-form/ServiceFormEdit.vue'),
    meta: {
      title: 'Edit Service Form',
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Service Forms',
          to: '/operations/service-forms/all-service-forms',
        },
        {
          text: 'Edit Service Form',
          active: true,
        },
      ],
      resource: ['ServiceForm', 'ServiceForm', 'ServiceForm', 'ServiceForm', 'ServiceForm', 'ServiceForm', 'ServiceForm', 'ServiceForm', 'ServiceForm'],
      action: ['update', 'update-applicant', 'update-deceased', 'update-service-description', 'update-agent', 'update-attachment', 'update-duty-officer', 'update-status', 'update-payment'],
    },
  },
  {
    path: '/operations/service-forms/:id/edit/payments-details',
    name: 'operation-service-forms-payments-edit',
    component: () => import('@/views/operation/service-form/ServiceFormPaymentEdit.vue'),
    meta: {
      title: 'Edit Service Form',
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Service Forms',
          to: '/operations/service-forms/all-service-forms',
        },
        {
          text: 'Edit Service Form',
          active: true,
        },
      ],
      resource: 'ServiceForm',
      action: 'update-payment',
    },
  },
  {
    path: '/operations/service-forms/:id/show',
    name: 'operation-service-forms-show',
    component: () => import('@/views/operation/service-form/ServiceFormDetail.vue'),
    meta: {
      title: 'Service Form Detail',
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Service Forms',
          to: '/operations/service-forms/all-service-forms',
        },
        {
          text: 'Service Form Detail',
          active: true,
        },
      ],
      resource: 'ServiceForm',
      action: 'show',
    },
  },
  {
    path: '/operations/service-forms/:id/form-view',
    name: 'operation-service-forms-form-view',
    component: () => import('@/views/operation/service-form/FormView.vue'),
    meta: {
      title: 'Service Form',
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Service Forms',
          to: '/operations/service-forms/all-service-forms',
        },
        {
          text: 'Service Form',
          active: true,
        },
      ],
      resource: 'ServiceForm',
      action: 'show',
    },
  },
  {
    path: '/operations/service-requests/:type',
    name: 'operation-service-requests-index',
    component: () => import('@/views/operation/service-request/ServiceRequestList.vue'),
    meta: {
      title: 'Service Requests',
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Service Requests',
          active: true,
        },
      ],
      resource: 'ServiceRequest',
      action: 'read',
    },
  },
  {
    path: '/operations/service-requests/kitchen-view/print/four-stickers',
    name: 'operation-service-requests-kitchen-view-print-four-stickers',
    component: () => import('@/views/operation/kitchen-view/FourStickerPreview.vue'),
    meta: {
      title: 'Service Requests Kitchen View Print Stickers',
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Kitchen View',
          to: '/operations/service-requests/kitchen-view/all-service-requests',
        },
        {
          text: 'Print Stickers',
          active: true,
        },
      ],
      resource: 'ServiceRequest',
    },
  },
  {
    path: '/operations/service-requests/kitchen-view/print/stickers',
    name: 'operation-service-requests-kitchen-view-print-stickers',
    component: () => import('@/views/operation/kitchen-view/StickerPreview.vue'),
    meta: {
      title: 'Service Requests Kitchen View Print Stickers',
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Kitchen View',
          to: '/operations/service-requests/kitchen-view/all-service-requests',
        },
        {
          text: 'Print Stickers',
          active: true,
        },
      ],
      resource: 'ServiceRequest',
    },
  },
  {
    path: '/operations/service-requests/kitchen-view/print/eight/stickers-two',
    name: 'operation-service-requests-kitchen-view-print-stickers-eight-two',
    component: () => import('@/views/operation/kitchen-view/PrintStickerEightTwo.vue'),
    meta: {
      title: 'Service Requests Kitchen View Print Stickers',
      pageTitle: 'Operations',
      layout: 'full',
      breadcrumb: [
        {
          text: 'Kitchen View',
          to: '/operations/service-requests/kitchen-view/all-service-requests',
        },
        {
          text: 'Print Stickers',
          active: true,
        },
      ],
      resource: 'ServiceRequest',
    },
  },
  {
    path: '/operations/service-requests/kitchen-view/:type',
    name: 'operation-service-requests-kitchen-view',
    component: () => import('@/views/operation/kitchen-view/ServiceRequestList.vue'),
    meta: {
      title: 'Service Requests Kitchen View',
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Kitchen’s View',
          active: true,
        },
      ],
      resource: 'ServiceRequest',
      action: 'kitchen-view',
    },
  },
  {
    path: '/operations/service-requests/new/create',
    name: 'operation-service-requests-create',
    component: () => import('@/views/operation/service-request/ServiceRequestCreate.vue'),
    meta: {
      title: 'Create New Service Request',
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Service Requests',
          to: '/operations/service-requests/all-service-requests',
        },
        {
          text: 'Create New Service Request',
          active: true,
        },
      ],
      resource: 'ServiceRequest',
      action: 'create',
    },
  },
  {
    path: '/operations/service-requests/:id/edit',
    name: 'operation-service-requests-edit',
    component: () => import('@/views/operation/service-request/ServiceRequestEdit.vue'),
    meta: {
      title: 'Edit Service Request',
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Service Requests',
          to: '/operations/service-requests/all-service-requests',
        },
        {
          text: 'Edit Service Request',
          active: true,
        },
      ],
      resource: ['ServiceRequest', 'ServiceRequest', 'ServiceRequest', 'ServiceRequest'],
      action: ['update', 'update-detail', 'update-assignee', 'update-status'],
    },
  },
  {
    path: '/operations/service-requests/:id/show',
    name: 'operation-service-requests-show',
    component: () => import('@/views/operation/service-request/ServiceRequestDetail.vue'),
    meta: {
      title: 'Service Request Detail',
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Service Requests',
          to: '/operations/service-requests/all-service-requests',
        },
        {
          text: 'Service Request Detail',
          active: true,
        },
      ],
      resource: 'ServiceRequest',
      action: 'show',
    },
  },

  {
    path: '/operations/service-requests/:id/form-view',
    name: 'operation-service-requests-form-view',
    component: () => import('@/views/operation/service-request/ServiceRequestForms.vue'),
    meta: {
      title: 'Service Request Form View',
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Service Requests',
          to: '/operations/service-requests/all-service-requests',
        },
        {
          text: 'Edit Forms',
          active: true,
        },
      ],
      resource: 'ServiceRequest',
      action: 'show',
    },
  },

  {
    path: '/operations/tasks',
    name: 'operation-service-requests-tasks-index',
    component: () => import('@/views/operation/tasks/TaskList.vue'),
    meta: {
      title: 'Tasks',
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Tasks',
          active: true,
        },
      ],
      resource: 'ServiceRequestTask',
      action: 'read',
    },
  },

  {
    path: '/operations/tasks/:id/show',
    name: 'operation-service-requests-tasks-show',
    component: () => import('@/views/operation/tasks/TaskDetail.vue'),
    meta: {
      title: 'Task Detail',
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Tasks',
          to: '/operations/tasks',
        },
        {
          text: 'Task Detail',
          active: true,
        },
      ],
      resource: 'ServiceRequestTask',
      action: 'show',
    },
  },
  {
    path: '/operations/reports/ceremonial-services',
    name: 'operation-ceremonial-services-report',
    component: () => import('@/views/operation/reports/CeremonialServices.vue'),
    meta: {
      title: 'Ceremonial Services Reports',
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Reports',
          to: '',
        },
        {
          text: 'Ceremonial Services',
          active: true,
        },
      ],
      resource: 'OperationReport',
      action: 'ceremonial-service',
    },
  },
  {
    path: '/operations/reports/call-logs',
    name: 'operation-call-logs-report',
    component: () => import('@/views/operation/reports/CallLogs.vue'),
    meta: {
      title: 'Call Logs Reports',
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Reports',
          to: '',
        },
        {
          text: 'Call Logs Reports',
          active: true,
        },
      ],
      resource: 'OperationReport',
      action: 'call-log',
    },
  },
  {
    path: '/operations/reports/appointments',
    name: 'operation-appointments-report',
    component: () => import('@/views/operation/reports/Appointments.vue'),
    meta: {
      title: 'Appointments Reports',
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Reports',
          to: '',
        },
        {
          text: 'Appointments Reports',
          active: true,
        },
      ],
      resource: 'OperationReport',
      action: 'appointments',
    },
  },
  {
    path: '/operations/reports/site-ops-urn-internment',
    name: 'operation-site-ops-urn-internment-report',
    component: () => import('@/views/operation/reports/DeceasedCategorized.vue'),
    meta: {
      title: 'Site Ops Urn Internment Reports',
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Reports',
          to: '',
        },
        {
          text: 'Site Ops Urn Internment Reports',
          active: true,
        },
      ],
      resource: 'OperationReport',
      action: 'site-ops-urn-internment',
    },
  },
  {
    path: '/operations/reports/customer-feedback',
    name: 'operation-customer-feedback-report',
    component: () => import('@/views/operation/reports/CustomerFeedback.vue'),
    meta: {
      title: 'Customer Feedback Reports',
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Reports',
          to: '',
        },
        {
          text: 'Customer Feedback Reports',
          active: true,
        },
      ],
      resource: 'OperationReport',
      action: 'customer-feedback',
    },
  },
  {
    path: '/operations/digital-forms',
    name: 'operation-digital-form-index',
    component: () => import('@/views/operation/digital-form/DigitalFormList.vue'),
    meta: {
      title: 'Form Templates',
      pageTitle: 'Operations',
      breadcrumb: [
        {
          text: 'Settings',
          to: '',
        },
        {
          text: 'Form Templates',
          active: true,
        },
      ],
      resource: 'FormTemplate',
      action: 'read',
    },
  },
  {
    path: '/operations/digital-forms/create',
    name: 'operation-digital-form-create',
    component: () => import('@/views/operation/digital-form/CreateDigitalForm.vue'),
    meta: {
      title: 'Create New Form Template',
      pageTitle: 'Operations',
      navActiveLink: 'operation-digital-form-index',
      breadcrumb: [
        {
          text: 'Settings',
          to: '',
        },
        {
          text: 'Form Templates',
          to: '/operations/digital-forms',
        },
        {
          text: 'Create New Form Template',
          active: true,
        },
      ],
      resource: 'FormTemplate',
      action: 'create',
    },
  },
  {
    path: '/operations/digital-forms/:id/edit',
    name: 'operation-digital-form-edit',
    component: () => import('@/views/operation/digital-form/EditDigitalForm.vue'),
    meta: {
      title: 'Edit Form Template',
      pageTitle: 'Operations',
      navActiveLink: 'operation-digital-form-index',
      breadcrumb: [
        {
          text: 'Settings',
          to: '',
        },
        {
          text: 'Form Templates',
          to: '/operations/digital-forms',
        },
        {
          text: 'Edit Form Template',
          active: true,
        },
      ],
      resource: 'FormTemplate',
      action: 'update',
    },
  },
  {
    path: '/operations/digital-forms/:id/show',
    name: 'operation-digital-form-show',
    component: () => import('@/views/operation/digital-form/DigitalFormDetail.vue'),
    meta: {
      title: 'Form Template Detail',
      pageTitle: 'Operations',
      navActiveLink: 'operation-digital-form-index',
      breadcrumb: [
        {
          text: 'Settings',
          to: '',
        },
        {
          text: 'Form Templates',
          to: '/operations/digital-forms',
        },
        {
          text: 'Form Template Detail',
          active: true,
        },
      ],
      resource: 'FormTemplate',
      action: 'show',
    },
  },
]
