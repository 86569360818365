export default [
  {
    path: '/budgeting/budget-overview',
    name: 'budgeting-budget-overview',
    component: () => import('@/views/budget/overview/Overview.vue'),
    meta: {
      title: 'Budget Overview',
      pageTitle: 'Budgeting',
      breadcrumb: [
        {
          text: 'Budget Overview',
          active: true,
        },
      ],
      resource: 'BudgetOverview',
      action: 'read',
    },
  },
  {
    path: '/budgeting/budget-overview/item-expenses',
    name: 'budgeting-budget-overview-item-expenses',
    component: () => import('@/views/budget/overview/ItemExpenses.vue'),
    meta: {
      title: 'Budget Overview Details',
      navActiveLink: 'budgeting-budget-overview',
      pageTitle: 'Budgeting',
      breadcrumb: [
        {
          text: 'Budget Overview',
          to: '/budgeting/budget-overview',
        },
        {
          text: 'View Related Expenses',
          active: true,
        },
      ],
      resource: 'BudgetOverview',
      action: 'see-expense',
    },
  },
  {
    path: '/budgeting/budget-setup',
    name: 'budgeting-budget-setup',
    component: () => import('@/views/budget/setup/SetupList.vue'),
    meta: {
      title: 'Budgets Setup',
      pageTitle: 'Budgeting',
      breadcrumb: [
        {
          text: 'Budgets Setup',
          active: true,
        },
      ],
      resource: 'BudgetSetup',
      action: 'read',
    },
  },
  {
    path: '/budgeting/budget-setup/create',
    name: 'budgeting-budget-setup-create',
    component: () => import('@/views/budget/setup/SetupCreate.vue'),
    meta: {
      title: 'Budgets Setup Create',
      pageTitle: 'Budgeting',
      navActiveLink: 'budgeting-budget-setup',
      breadcrumb: [
        {
          text: 'Setup',
          to: '/budgeting/budget-setup',
        },
        {
          text: 'Create New Budget',
          active: true,
        },
      ],
      resource: 'BudgetSetup',
      action: 'create',
    },
  },
  {
    path: '/budgeting/budget-setup/preview',
    name: 'budgeting-budget-setup-preview',
    component: () => import('@/views/budget/setup/SetupPreview.vue'),
    meta: {
      title: 'Budgets Setup',
      pageTitle: 'Budgeting',
      navActiveLink: 'budgeting-budget-setup',
      breadcrumb: [
        {
          text: 'Setup',
          to: '/budgeting/budget-setup',
        },
        {
          text: 'Create New Budget',
          to: '/budgeting/budget-setup/create',
        },
        {
          text: 'Preview New Budget',
          active: true,
        },
      ],
      resource: 'BudgetSetup',
      action: 'create',
    },
  },
  {
    path: '/budgeting/budget-setup/edit-preview',
    name: 'budgeting-budget-setup-edit-preview',
    component: () => import('@/views/budget/setup/EditSetupPreview.vue'),
    meta: {
      title: 'Budgets Setup',
      pageTitle: 'Budgeting',
      navActiveLink: 'budgeting-budget-setup',
      breadcrumb: [
        {
          text: 'Setup',
          to: '/budgeting/budget-setup',
        },
        {
          text: 'Preview Edit Budget',
          active: true,
        },
      ],
      resource: 'BudgetSetup',
      action: 'update',
    },
  },
  {
    path: '/budgeting/budget-setup/:id/edit',
    name: 'budgeting-budget-setup-edit',
    component: () => import('@/views/budget/setup/SetupEdit.vue'),
    meta: {
      title: 'Budgets Setup',
      pageTitle: 'Budgeting',
      navActiveLink: 'budgeting-budget-setup',
      breadcrumb: [
        {
          text: 'Setup',
          to: '/budgeting/budget-setup',
        },
        {
          text: 'Edit Budget Setup',
          active: true,
        },
      ],
      resource: 'BudgetSetup',
      action: 'update',
    },
  },
  {
    path: '/budgeting/budget-setup/:id/show',
    name: 'budgeting-budget-setup-view',
    component: () => import('@/views/budget/setup/SetupDetail.vue'),
    meta: {
      title: 'Budgets Setup',
      pageTitle: 'Budgeting',
      navActiveLink: 'budgeting-budget-setup',
      breadcrumb: [
        {
          text: 'Setup',
          to: '/budgeting/budget-setup',
        },
        {
          text: 'Budget Setup Detail',
          active: true,
        },
      ],
      resource: 'BudgetSetup',
      action: 'show',
    },
  },
  {
    path: '/budgeting/expense-categories',
    name: 'budgeting-expense-categories',
    component: () => import('@/views/budget/expense-categories/ExpenseCategoryList.vue'),
    meta: {
      title: 'Expense Categories',
      pageTitle: 'Budgeting',
      breadcrumb: [
        {
          text: 'Expense Categories',
          active: true,
        },
      ],
      resource: 'ExpenseCategory',
      action: 'read',
    },
  },
  {
    path: '/budgeting/cost-center',
    name: 'budgeting-cost-center',
    component: () => import('@/views/budget/cost-center/CostCenterList.vue'),
    meta: {
      title: 'Cost Centre',
      pageTitle: 'Budgeting',
      breadcrumb: [
        {
          text: 'Cost Centre',
          active: true,
        },
      ],
      resource: 'CostCenter',
      action: 'read',
    },
  },
  {
    path: '/budgeting/budgetary-periods',
    name: 'budgeting-budgetary-periods',
    component: () => import('@/views/budget/period/PeriodList.vue'),
    meta: {
      title: 'Budgetary Periods',
      pageTitle: 'Budgeting',
      breadcrumb: [
        {
          text: 'Budgetary Periods',
          active: true,
        },
      ],
      resource: 'BudgetPeriod',
      action: 'read',
    },
  },
]
