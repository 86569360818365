import ability from './ability'

export const canNavigate = to => to.matched.some(route => {
  if (route.meta.action && Array.isArray(route.meta.action)) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < route.meta.action.length; i++) {
      const canView = ability.can(route.meta.action[i], route.meta.resource[i])
      if (canView) {
        return true
      }
    }

    return false
  }
  return ability.can(route.meta.action || 'read', route.meta.resource)
})

export const _ = undefined
