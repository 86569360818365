export default {
  namespaced: true,
  state: {
    breadcrumbs: [],
  },
  mutations: {
    ADD_BREADCRUMB(state, val) {
      state.breadcrumbs = val
    },
    UPDATE_BREADCRUMB(state, val) {
      state.breadcrumbs.push(val)
    },
    REMOVE_BREADCRUMB(state) {
      state.breadcrumbs.pop()
    },
  },
  actions: {},
  getters: {},
}
