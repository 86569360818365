export default [
  {
    path: '/directory/business-entities',
    name: 'directory-business-entities-index',
    component: () => import('@/views/directory/business-entities/BusinessEntityList.vue'),
    meta: {
      title: 'Business Entities',
      pageTitle: 'Directory',
      breadcrumb: [
        {
          text: 'Business Entities',
          active: true,
        },
      ],
      resource: 'BusinessEntity',
      action: 'read',
    },
  },
  {
    path: '/directory/business-entities/create',
    name: 'directory-business-entities-create',
    component: () => import('@/views/directory/business-entities/BusinessEntityCreate.vue'),
    meta: {
      title: 'Create New Business Entity',
      pageTitle: 'Directory',
      navActiveLink: 'directory-business-entities-index',
      breadcrumb: [
        {
          text: 'Business Entities',
          to: '/directory/business-entities',
        },
        {
          text: 'Create New Business Entity',
          active: true,
        },
      ],
      resource: 'BusinessEntity',
      action: 'create',
    },
  },
  {
    path: '/directory/business-entities/:id/edit',
    name: 'directory-business-entities-edit',
    component: () => import('@/views/directory/business-entities/BusinessEntityEdit.vue'),
    meta: {
      title: 'Edit Business Entity',
      pageTitle: 'Directory',
      navActiveLink: 'directory-business-entities-index',
      breadcrumb: [
        {
          text: 'Business Entities',
          to: '/directory/business-entities',
        },
        {
          text: 'Edit Business Entity',
          active: true,
        },
      ],
      resource: 'BusinessEntity',
      action: 'update',
    },
  },
  {
    path: '/directory/app-clients',
    name: 'directory-app-clients-index',
    component: () => import('@/views/directory/app-clients/AppClientList.vue'),
    meta: {
      title: 'App Clients',
      pageTitle: 'Directory',
      breadcrumb: [
        {
          text: 'App Clients',
          active: true,
        },
      ],
      resource: 'AppClient',
      action: 'read',
    },
  },
  {
    path: '/directory/app-clients/create',
    name: 'directory-app-clients-create',
    component: () => import('@/views/directory/app-clients/AppClientCreate.vue'),
    meta: {
      title: 'Create New App Client',
      pageTitle: 'Directory',
      navActiveLink: 'directory-app-clients-index',
      breadcrumb: [
        {
          text: 'App Clients',
          to: '/directory/app-clients',
        },
        {
          text: 'Create New App Client',
          active: true,
        },
      ],
      resource: 'AppClient',
      action: 'create',
    },
  },
  {
    path: '/directory/app-clients/:id/edit',
    name: 'directory-app-clients-edit',
    component: () => import('@/views/directory/app-clients/AppClientEdit.vue'),
    meta: {
      title: 'Edit App Client',
      pageTitle: 'Directory',
      navActiveLink: 'directory-app-clients-index',
      breadcrumb: [
        {
          text: 'App Clients',
          to: '/directory/app-clients',
        },
        {
          text: 'Edit App Client',
          active: true,
        },
      ],
      resource: 'AppClient',
      action: 'update',
    },
  },
  {
    path: '/directory/roles',
    name: 'directory-roles-index',
    component: () => import('@/views/directory/roles/RoleList.vue'),
    meta: {
      title: 'Roles',
      pageTitle: 'Directory',
      breadcrumb: [
        {
          text: 'Roles',
          active: true,
        },
      ],
      resource: 'Role',
      action: 'read',
    },
  },
  {
    path: '/directory/roles/create',
    name: 'directory-roles-create',
    component: () => import('@/views/directory/roles/RoleCreate.vue'),
    meta: {
      title: 'Create New Role',
      pageTitle: 'Directory',
      navActiveLink: 'directory-roles-index',
      breadcrumb: [
        {
          text: 'Roles',
          to: '/directory/roles',
        },
        {
          text: 'Create New Role',
          active: true,
        },
      ],
      resource: 'Role',
      action: 'create',
    },
  },
  {
    path: '/directory/roles/:id/show',
    name: 'directory-roles-show',
    component: () => import('@/views/directory/roles/RoleDetail.vue'),
    meta: {
      title: 'Role Detail',
      pageTitle: 'Directory',
      navActiveLink: 'directory-roles-index',
      breadcrumb: [
        {
          text: 'Roles',
          to: '/directory/roles',
        },
        {
          text: 'Role Detail',
          active: true,
        },
      ],
      resource: 'Role',
      action: 'show',
    },
  },
  {
    path: '/directory/roles/:id/edit',
    name: 'directory-roles-edit',
    component: () => import('@/views/directory/roles/RoleEdit.vue'),
    meta: {
      title: 'Edit Role',
      pageTitle: 'Directory',
      navActiveLink: 'directory-roles-index',
      breadcrumb: [
        {
          text: 'Roles',
          to: '/directory/roles',
        },
        {
          text: 'Edit Role',
          active: true,
        },
      ],
      resource: 'Role',
      action: 'update',
    },
  },
  {
    path: '/directory/addresses',
    name: 'directory-addresses-index',
    component: () => import('@/views/directory/addresses/AddressList.vue'),
    meta: {
      title: 'Addresses',
      pageTitle: 'Directory',
      breadcrumb: [
        {
          text: 'Addresses',
          active: true,
        },
      ],
      resource: 'Address',
      action: 'read',
    },
  },
  {
    path: '/directory/addresses/create',
    name: 'directory-addresses-create',
    component: () => import('@/views/directory/addresses/AddressesCreate.vue'),
    meta: {
      title: 'Create Address',
      pageTitle: 'Directory',
      navActiveLink: 'directory-addresses-index',
      breadcrumb: [
        {
          text: 'Addresses',
          to: '/directory/addresses',
        },
        {
          text: 'Create Address',
          active: true,
        },
      ],
      resource: 'Address',
      action: 'create',
    },
  },
  {
    path: '/directory/addresses/:id/edit',
    name: 'directory-addresses-edit',
    component: () => import('@/views/directory/addresses/AddressEdit.vue'),
    meta: {
      title: 'Edit Address',
      pageTitle: 'Directory',
      navActiveLink: 'directory-addresses-index',
      breadcrumb: [
        {
          text: 'Addresses',
          to: '/directory/addresses',
        },
        {
          text: 'Edit Address',
          active: true,
        },
      ],
      resource: 'Address',
      action: 'update',
    },
  },
  {
    path: '/directory/departments',
    name: 'directory-departments-index',
    component: () => import('@/views/directory/departments/DepartmentList.vue'),
    meta: {
      title: 'Departments',
      pageTitle: 'Directory',
      breadcrumb: [
        {
          text: 'Departments',
          active: true,
        },
      ],
      resource: 'Department',
      action: 'read',
    },
  },
  {
    path: '/directory/departments/create',
    name: 'directory-departments-create',
    component: () => import('@/views/directory/departments/DepartmentCreate.vue'),
    meta: {
      title: 'Create Department',
      pageTitle: 'Directory',
      navActiveLink: 'directory-departments-index',
      breadcrumb: [
        {
          text: 'Departments',
          to: '/directory/departments',
        },
        {
          text: 'Create Department',
          active: true,
        },
      ],
      resource: 'Department',
      action: 'create',
    },
  },
  {
    path: '/directory/departments/:id/edit',
    name: 'directory-departments-edit',
    component: () => import('@/views/directory/departments/DepartmentEdit.vue'),
    meta: {
      title: 'Edit Department',
      pageTitle: 'Directory',
      navActiveLink: 'directory-departments-index',
      breadcrumb: [
        {
          text: 'Departments',
          to: '/directory/departments',
        },
        {
          text: 'Edit Department',
          active: true,
        },
      ],
      resource: 'Department',
      action: 'update',
    },
  },
  {
    path: '/directory/teams',
    name: 'directory-teams-index',
    component: () => import('@/views/directory/teams/TeamList.vue'),
    meta: {
      pageTitle: 'Directory',
      breadcrumb: [
        {
          text: 'Teams',
          active: true,
        },
      ],
      resource: 'Team',
      action: 'read',
    },
  },
  {
    path: '/directory/teams/create',
    name: 'directory-teams-create',
    component: () => import('@/views/directory/teams/TeamCreate.vue'),
    meta: {
      pageTitle: 'Directory',
      navActiveLink: 'directory-teams-index',
      breadcrumb: [
        {
          text: 'Teams',
          to: '/directory/teams',
        },
        {
          text: 'Create Team',
          active: true,
        },
      ],
      resource: 'Team',
      action: 'create',
    },
  },
  {
    path: '/directory/teams/:id/edit',
    name: 'directory-teams-edit',
    component: () => import('@/views/directory/teams/TeamEdit.vue'),
    meta: {
      pageTitle: 'Directory',
      navActiveLink: 'directory-teams-index',
      breadcrumb: [
        {
          text: 'Teams',
          to: '/directory/teams',
        },
        {
          text: 'Edit Team',
          active: true,
        },
      ],
      resource: 'Team',
      action: 'update',
    },
  },
  {
    path: '/directory/teams/:id/show',
    name: 'directory-teams-show',
    component: () => import('@/views/directory/teams/TeamDetail.vue'),
    meta: {
      pageTitle: 'Directory',
      navActiveLink: 'directory-teams-index',
      breadcrumb: [
        {
          text: 'Teams',
          to: '/directory/teams',
        },
        {
          text: 'Team Detail',
          active: true,
        },
      ],
      resource: 'Team',
      action: 'show',
    },
  },
  {
    path: '/directory/users',
    name: 'directory-users-index',
    component: () => import('@/views/directory/users/UserList.vue'),
    meta: {
      title: 'Users',
      pageTitle: 'Directory',
      breadcrumb: [
        {
          text: 'Users',
          active: true,
        },
      ],
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/directory/users/create',
    name: 'directory-users-create',
    component: () => import('@/views/directory/users/UserCreate.vue'),
    meta: {
      title: 'Create User',
      pageTitle: 'Directory',
      navActiveLink: 'directory-users-index',
      breadcrumb: [
        {
          text: 'Users',
          to: '/directory/users',
        },
        {
          text: 'Create User',
          active: true,
        },
      ],
      resource: 'User',
      action: 'create',
    },
  },
  {
    path: '/directory/users/:id/show',
    name: 'directory-users-show',
    component: () => import('@/views/directory/users/UserDetail.vue'),
    meta: {
      title: 'User Detail',
      pageTitle: 'Directory',
      navActiveLink: 'directory-users-index',
      breadcrumb: [
        {
          text: 'Users',
          to: '/directory/users',
        },
        {
          text: 'User Detail',
          active: true,
        },
      ],
      resource: 'User',
      action: 'show',
    },
  },
  {
    path: '/directory/users/:id/edit',
    name: 'directory-users-edit',
    component: () => import('@/views/directory/users/UserEdit.vue'),
    meta: {
      title: 'Edit User',
      pageTitle: 'Directory',
      navActiveLink: 'directory-users-index',
      breadcrumb: [
        {
          text: 'Users',
          to: '/directory/users',
        },
        {
          text: 'Edit User',
          active: true,
        },
      ],
      resource: 'User',
      action: 'update',
    },
  },
  {
    path: '/directory/users/:id/change-password',
    name: 'directory-users-change-password',
    component: () => import('@/views/directory/users/UserPasswordChange.vue'),
    meta: {
      title: 'Change User Password',
      pageTitle: 'Directory',
      navActiveLink: 'directory-users-index',
      breadcrumb: [
        {
          text: 'Users',
          to: '/directory/users',
        },
        {
          text: 'Change User Password',
          active: true,
        },
      ],
      resource: 'User',
      action: 'change-password',
    },
  },
  {
    path: '/directory/my-profile',
    name: 'directory-my-profile',
    component: () => import('@/views/directory/users/UserProfile.vue'),
    meta: {
      title: 'My Profile',
      pageTitle: 'Directory',
      breadcrumb: [
        {
          text: 'My Profile',
          active: true,
        },
      ],
      resource: 'User',
    },
  },
  {
    path: '/directory/my-profile/update',
    name: 'directory-my-profile-update',
    component: () => import('@/views/directory/users/UserProfileEdit.vue'),
    meta: {
      title: 'Update My Profile',
      pageTitle: 'Directory',
      breadcrumb: [
        {
          text: 'My Profile',
          to: '/directory/my-profile',
        },
        {
          text: 'Update',
          active: true,
        },
      ],
      resource: 'User',
    },
  },
  {
    path: '/directory/my-profile/change-password',
    name: 'directory-my-profile-change-password',
    component: () => import('@/views/directory/users/ChangePassword.vue'),
    meta: {
      title: 'Change Password',
      pageTitle: 'Directory',
      breadcrumb: [
        {
          text: 'My Profile',
          to: '/directory/my-profile',
        },
        {
          text: 'Change Password',
          active: true,
        },
      ],
      resource: 'User',
    },
  },
]
