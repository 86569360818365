import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import IdleVue from 'idle-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueSignaturePad from 'vue-signature-pad'

import i18n from '@/libs/i18n'
import '@/mixins/helper'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/scroll-active'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueSignaturePad)

// Composition API
Vue.use(VueCompositionAPI)

const idleEmitter = new Vue()
Vue.use(IdleVue, {
  eventEmitter: idleEmitter,
  idleTime: process.env.VUE_APP_MAX_IDLE_TIME,
})

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  onIdle() {
    // eslint-disable-next-line eqeqeq
    if (store.getters['auth/isLoggedIn'] && localStorage.getItem('remember_me') === 'false') {
      store
        .dispatch('auth/unauthorizedLogout')
        .then(() => router.replace('/login'))
    }
  },
}).$mount('#app')
