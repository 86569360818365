export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/MyDashboard.vue'),
    meta: {
      title: 'My Dashboard',
      pageTitle: 'Dashboard',
      resource: 'Dashboard',
      action: 'my-dashboard',
      breadcrumb: [
        {
          text: 'My Dashboard',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dashboard/ecommerce',
    name: 'dashboard-ecommerce',
    component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
    meta: {
      title: 'Dashboard',
      resource: 'Dashboard',
      action: 'read',
    },
  },
  {
    path: '/dashboard/settings/dashboard/list',
    name: 'dashboard-views-index',
    component: () => import('@/views/dashboard/settings/DashboardList.vue'),
    meta: {
      title: 'Dashboard Views List',
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Dashboard Views',
          active: true,
        },
      ],
      resource: 'Dashboard',
      action: 'read',
    },
  },
  {
    path: '/dashboard/settings/dashboard/create',
    name: 'dashboard-views-create',
    component: () => import('@/views/dashboard/settings/DashboardCreate.vue'),
    meta: {
      title: 'Dashboard Views Create',
      pageTitle: 'Dashboard',
      navActiveLink: 'dashboard-views-index',
      breadcrumb: [
        {
          text: 'Dashboard Views',
          to: '/dashboard/settings/dashboard/list',
        },
        {
          text: 'Create New Dashboard View',
          active: true,
        },
      ],
      resource: 'Dashboard',
      action: 'create',
    },
  },
  {
    path: '/dashboard/settings/dashboard/:id/show',
    name: 'dashboard-views-show',
    component: () => import('@/views/dashboard/settings/DashboardView.vue'),
    meta: {
      title: 'Dashboard Views View',
      pageTitle: 'Dashboard',
      navActiveLink: 'dashboard-views-index',
      breadcrumb: [
        {
          text: 'Dashboard Views',
          to: '/dashboard/settings/dashboard/list',
        },
        {
          text: 'Dashboard View',
          active: true,
        },
      ],
      resource: 'Dashboard',
      action: 'show',
    },
  },
  {
    path: '/dashboard/settings/dashboard/:id/edit',
    name: 'dashboard-views-edit',
    component: () => import('@/views/dashboard/settings/DashboardEdit.vue'),
    meta: {
      title: 'Dashboard Views Edit',
      pageTitle: 'Dashboard',
      navActiveLink: 'dashboard-views-index',
      name: 'dashboard-views-edit',
      breadcrumb: [
        {
          text: 'Dashboard Views',
          to: '/dashboard/settings/dashboard/list',
        },
        {
          text: 'Edit Dashboard View',
          active: true,
        },
      ],
      resource: 'Dashboard',
      action: 'update',
    },
  },
]
