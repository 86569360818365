export default [
  {
    path: '/agencies/agents',
    name: 'agency-agents-index',
    component: () => import('@/views/agency/agents/AgentList.vue'),
    meta: {
      title: 'Agents',
      pageTitle: 'Agencies',
      breadcrumb: [
        {
          text: 'Agents',
          active: true,
        },
      ],
      resource: 'Agent',
      action: 'read',
    },
  },
  {
    path: '/agencies/agents/:id/show',
    name: 'agency-agents-show',
    component: () => import('@/views/agency/agents/AgentDetail.vue'),
    meta: {
      title: 'Agent Details',
      pageTitle: 'Agencies',
      navActiveLink: 'agency-agents-index',
      breadcrumb: [
        {
          text: 'Agents',
          to: '/agencies/agents',
        },
      ],
      resource: 'Agent',
      action: 'read',
    },
  },
  {
    path: '/agencies/agents/:id/edit',
    name: 'agency-agents-edit',
    component: () => import('@/views/agency/agents/AgentEdit.vue'),
    meta: {
      title: 'Agent Edit',
      pageTitle: 'Agencies',
      navActiveLink: 'agency-agents-index',
      breadcrumb: [
        {
          text: 'Agents',
          to: '/agencies/agents',
        },
      ],
      resource: 'Agent',
      action: 'update',
    },
  },
]
