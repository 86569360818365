import axios from '@axios'

export default {
  namespaced: true,
  state: {
    status: '',
    token: '',
    userData: {},
  },
  mutations: {
    auth_request(state) {
      state.status = 'loading'
    },
    auth_success(state, { accessToken, userData }) {
      state.status = 'success'
      state.token = accessToken
      state.userData = userData
    },
    auth_error(state) {
      state.status = 'error'
      state.token = ''
      state.userData = {}
    },
    update_image(state, { userImage, signature }) {
      state.userData = { ...state.userData, image: userImage, signature }
    },
    logout(state) {
      state.status = ''
      state.token = ''
      state.userData = {}
    },
  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios
          .post('login', user, { withCredentials: true })
          .then(response => {
            // eslint-disable-next-line prefer-destructuring
            const { accessToken, userData } = response.data
            commit('auth_success', { accessToken, userData })
            resolve(response)
          })
          .catch(err => {
            commit('auth_error')
            reject(err)
          })
      })
    },

    refreshToken({ commit }) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios
          .post('refresh-token', {}, { withCredentials: true })
          .then(response => {
            // eslint-disable-next-line prefer-destructuring
            const { accessToken, userData } = response.data
            commit('auth_success', { accessToken, userData })
            resolve(response)
          })
          .catch(err => {
            commit('auth_error')
            reject(err)
          })
      })
    },

    logout({ commit }) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        axios
          .post('logout')
          .then(response => {
            commit('logout')
            resolve(response)
          })
          .catch(err => {
            commit('logout')
            reject(err)
          })
      })
    },

    unauthorizedLogout({ commit }) {
      return new Promise((resolve, reject) => {
        try {
          commit('logout')
          resolve('ok')
        } catch (error) {
          reject(error)
        }
      })
    },
  },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
  },
}
