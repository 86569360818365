export default [
  {
    path: '/inventory/stock-list',
    name: 'inventory-stock-list',
    component: () => import('@/views/inventory/stock/StockList.vue'),
    meta: {
      title: 'Stock List',
      pageTitle: 'Inventory',
      breadcrumb: [
        {
          text: 'Stock List',
          active: true,
        },
      ],
      resource: ['StockList', 'StockList'],
      action: ['read', 'list-view-all'],
    },
  },
  {
    path: '/inventory/stock-in',
    name: 'inventory-stock-in',
    component: () => import('@/views/inventory/stock/StockIn.vue'),
    meta: {
      title: 'Stock In',
      pageTitle: 'Inventory',
      navActiveLink: 'inventory-stock-list',
      breadcrumb: [
        {
          text: 'Stock List',
          to: '/inventory/stock-list',
          active: false,
        },
        {
          text: 'Stock In',
          active: true,
        },
      ],
      resource: 'StockList',
      action: 'stock-in',
    },
  },
  {
    path: '/inventory/stock-out',
    name: 'inventory-stock-out',
    component: () => import('@/views/inventory/stock/StockOut.vue'),
    meta: {
      title: 'Stock Out',
      pageTitle: 'Inventory',
      navActiveLink: 'inventory-stock-list',
      breadcrumb: [
        {
          text: 'Stock List',
          to: '/inventory/stock-list',
          active: false,
        },
        {
          text: 'Stock Out',
          active: true,
        },
      ],
      resource: 'StockList',
      action: 'stock-out',
    },
  },
  {
    path: '/inventory/stock-adjustment',
    name: 'inventory-stock-adjustment',
    component: () => import('@/views/inventory/stock/StockAdjustment.vue'),
    meta: {
      title: 'Stock Adjustment',
      pageTitle: 'Inventory',
      navActiveLink: 'inventory-stock-list',
      breadcrumb: [
        {
          text: 'Stock List',
          to: '/inventory/stock-list',
          active: false,
        },
        {
          text: 'Adjust',
          active: true,
        },
      ],
      resource: 'StockList',
      action: 'stock-adjustment',
    },
  },
  {
    path: '/inventory/stock-transfer',
    name: 'inventory-stock-transfer',
    component: () => import('@/views/inventory/stock/StockTransfer.vue'),
    meta: {
      title: 'Stock Transfer',
      pageTitle: 'Inventory',
      navActiveLink: 'inventory-stock-list',
      breadcrumb: [
        {
          text: 'Stock List',
          to: '/inventory/stock-list',
          active: false,
        },
        {
          text: 'Stock Transfer',
          active: true,
        },
      ],
      resource: 'StockList',
      action: 'stock-transfer',
    },
  },
  {
    path: '/inventory/categories',
    name: 'inventory-category-list',
    component: () => import('@/views/inventory/categories/CategoryList.vue'),
    meta: {
      title: 'Category List',
      pageTitle: 'Inventory',
      breadcrumb: [
        {
          text: 'Category List',
          active: true,
        },
      ],
      resource: 'InventoryCategory',
      action: 'read',
    },
  },
  {
    path: '/inventory/category/create',
    name: 'inventory-category-create',
    component: () => import('@/views/inventory/categories/CategoryCreate.vue'),
    meta: {
      title: 'Create New Category',
      pageTitle: 'Inventory',
      navActiveLink: 'inventory-category-list',
      breadcrumb: [
        {
          text: 'Categories',
          to: '/inventory/categories',
        },
        {
          text: 'Create New Category',
          active: true,
        },
      ],
      resource: 'InventoryCategory',
      action: 'create',
    },
  },
  {
    path: '/inventory/category/:id/update',
    name: 'inventory-category-edit',
    component: () => import('@/views/inventory/categories/CategoryEdit.vue'),
    meta: {
      title: 'Edit Category',
      pageTitle: 'Inventory',
      navActiveLink: 'inventory-category-list',
      breadcrumb: [
        {
          text: 'Categories',
          to: '/inventory/categories',
        },
        {
          text: 'Edit Category',
          active: true,
        },
      ],
      resource: 'InventoryCategory',
      action: 'update',
    },
  },
  {
    path: '/inventory/stores',
    name: 'inventory-store-list',
    component: () => import('@/views/inventory/stores/StoreList.vue'),
    meta: {
      title: 'Store Lists',
      pageTitle: 'Inventory',
      breadcrumb: [
        {
          text: 'Stores',
          active: true,
        },
      ],
      resource: 'InventoryStore',
      action: 'read',
    },
  },
  {
    path: '/inventory/store/create',
    name: 'inventory-store-create',
    component: () => import('@/views/inventory/stores/StoreCreate.vue'),
    meta: {
      title: 'Create New Store',
      pageTitle: 'Inventory',
      navActiveLink: 'inventory-store-list',
      breadcrumb: [
        {
          text: 'Stores',
          to: '/inventory/stores',
        },
        {
          text: 'Create New Store',
          active: true,
        },
      ],
      resource: 'InventoryStore',
      action: 'create',
    },
  },
  {
    path: '/inventory/store/:id/update',
    name: 'inventory-store-edit',
    component: () => import('@/views/inventory/stores/StoreEdit.vue'),
    meta: {
      title: 'Edit Store',
      pageTitle: 'Inventory',
      navActiveLink: 'inventory-store-list',
      breadcrumb: [
        {
          text: 'Stores',
          to: '/inventory/stores',
        },
        {
          text: 'Edit Store',
          active: true,
        },
      ],
      resource: 'InventoryStore',
      action: 'update',
    },
  },
  {
    path: '/inventory/inventory-items',
    name: 'inventory-inventory-items-list',
    component: () => import('@/views/inventory/inventory-items/InventoryItemList.vue'),
    meta: {
      title: 'Inventory Items',
      pageTitle: 'Inventory',
      breadcrumb: [
        {
          text: 'Inventory Items',
          active: true,
        },
      ],
      resource: 'InventoryItem',
      action: 'read',
    },
  },
  {
    path: '/inventory/inventory-items/create',
    name: 'inventory-inventory-items-create',
    component: () => import('@/views/inventory/inventory-items/InventoryItemCreate.vue'),
    meta: {
      title: 'Register New Item',
      pageTitle: 'Inventory',
      navActiveLink: 'inventory-inventory-items-list',
      breadcrumb: [
        {
          text: 'Inventory Items',
          to: '/inventory/inventory-items',
        },
        {
          text: 'Register New Item',
          active: true,
        },
      ],
      resource: 'InventoryItem',
      action: 'create',
    },
  },
  {
    path: '/inventory/inventory-items/:id/update',
    name: 'inventory-inventory-items-edit',
    component: () => import('@/views/inventory/inventory-items/InventoryItemEdit.vue'),
    meta: {
      title: 'Edit Inventory Item',
      pageTitle: 'Inventory',
      navActiveLink: 'inventory-inventory-items-list',
      breadcrumb: [
        {
          text: 'Inventory Items',
          to: '/inventory/inventory-items',
        },
        {
          text: 'Edit Inventory Item',
          active: true,
        },
      ],
      resource: 'InventoryItem',
      action: 'update',
    },
  },
  {
    path: '/inventory/reports/urn-stock-movement',
    name: 'inventory-urn-stock-movement-report',
    component: () => import('@/views/inventory/reports/UrnStockMovement.vue'),
    meta: {
      title: 'Urn Stock Movement Report',
      pageTitle: 'Inventory',
      breadcrumb: [
        {
          text: 'Urn Stock Movement Report',
          active: true,
        },
      ],
      resource: 'InventoryReports',
      action: 'urn-stock-movement',
    },
  },
  {
    path: '/inventory/history',
    name: 'inventory-inventory-history',
    component: () => import('@/views/inventory/history/HistoryList.vue'),
    meta: {
      title: 'Inventory History',
      pageTitle: 'Inventory',
      breadcrumb: [
        {
          text: 'History',
          active: true,
        },
      ],
      resource: 'InventoryTransaction',
      action: 'read',
    },
  },
  {
    path: '/inventory/inventory-request/:type',
    name: 'inventory-inventory-requests-index',
    component: () => import('@/views/inventory/requests/InventoryRequestList.vue'),
    meta: {
      title: 'Inventory Requests',
      pageTitle: 'Inventory',
      breadcrumb: [
        {
          text: 'Inventory Requests',
          active: true,
        },
      ],
      resource: 'InventoryRequest',
      action: 'read',
    },
  },
  {
    path: '/inventory/inventory-request/:id/show',
    name: 'inventory-inventory-request-view',
    component: () => import('@/views/inventory/requests/InventoryRequestDetail.vue'),
    meta: {
      title: 'Inventory Request Detail',
      pageTitle: 'Inventory',
      navActiveLink: 'inventory-inventory-requests-index',
      breadcrumb: [
        {
          text: 'Inventory Requests',
          to: '/inventory/inventory-request/all-requests',
        },
        {
          text: 'Request Detail',
          active: true,
        },
      ],
      resource: 'InventoryRequest',
      action: 'show',
    },
  },
  {
    path: '/inventory/inventory-request/:id/update',
    name: 'inventory-inventory-request-edit',
    component: () => import('@/views/inventory/requests/InventoryRequestEdit.vue'),
    meta: {
      title: 'Edit Request',
      pageTitle: 'Inventory',
      navActiveLink: 'inventory-inventory-requests-index',
      breadcrumb: [
        {
          text: 'Inventory Requests',
          to: '/inventory/inventory-request/all-requests',
        },
        {
          text: 'Edit Request',
          active: true,
        },
      ],
      resource: 'InventoryRequest',
      action: 'update',
    },
  },
]
