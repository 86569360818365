export default [
  {
    path: '/user',
    name: 'user-index',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
    meta: {
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Users',
        },
        {
          text: 'User List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user/:id',
    name: 'user-view',
    component: () => import('@/views/apps/user/users-view/UsersView.vue'),
  },
]
