import { Ability } from '@casl/ability'
import store from '@/store/index'
import { initialAbility } from './config'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
// eslint-disable-next-line prefer-destructuring
const userData = store.state.auth.userData
const existingAbility = userData ? userData.permissions : null

export default new Ability(existingAbility || initialAbility)
